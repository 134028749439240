<template>
  <div>
    <Slick ref="slick" :options="slickOptions">
      <span v-for="item in items" :key="item.comapny">
        <div>
          <img class="max-w-11rem max-w-md-13rem mx-auto" :src="require('@/assets/img/partners/' + item.image_url)">
        </div>
      </span>
    </Slick>
  </div>
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'PartnerCarousel',
  components: {
    Slick
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      slickOptions: {
        prevArrow: false,
        nextArrow: false,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        infinite: true,
        'responsive': [{
          'breakpoint': 1200,
          'settings': {
            'slidesToShow': 4
          }
        }, {
          'breakpoint': 992,
          'settings': {
            'slidesToShow': 4
          }
        }, {
          'breakpoint': 768,
          'settings': {
            'slidesToShow': 3
          }
        }, {
          'breakpoint': 576,
          'settings': {
            'slidesToShow': 3
          }
        }]
      }
    }
  },
  computed: {
    // sortedItems() {
    //   return orderBy(this.items, 'displayOrder')
    // }
  }
}
</script>
<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';
</style>
